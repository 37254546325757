import React, { useEffect, useState, useContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useAuth } from '@hooks';
import { LayoutMissionMobile, LayoutMissionDesktop } from '@components';
import { BudgetCalculator, SEO } from '@components';
import { MissionsInfo } from '@data';
import { AppContext } from '@context';
import { nextMissionModalInfo } from '@utils';
import { useApolloClient } from '@apollo/client';
import { completeMissionMutation, getUserMissions } from '@apollo';
import { M5BudgetStep1, M5BudgetStep2, M5BudgetStep3 } from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';

const BudgetMission5 = () => {
  const isMobile = useMediaQuery('(max-width: 1240px)');
  useAuth({ redirectIfNotLogged: true });
  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const [confettiFire, setConfettiFire] = useState<boolean>(false);
  const { openCongratsModal } = useContext(AppContext);
  const [page, setPage] = useState<number>(1);
  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.BudgetCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.BudgetCategory.m5.id
  )[0];

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  const [antExpenses, setAntExpenses] = useState([
    { name: '', amount: 0, category: 'Compras' },
  ]);

  if (page === 1) {
    return (
      <>
        <SEO />

        {isMobile ? (
          <LayoutMissionMobile
            title={'Gastos hormigas'}
            confettiFire={confettiFire}
            number={5}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep1 setPage={setPage} />
          </LayoutMissionMobile>
        ) : (
          <LayoutMissionDesktop
            title={'Gastos hormigas'}
            confettiFire={confettiFire}
            number={5}
            numberAdjust={-0.17}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep1 setPage={setPage} />
          </LayoutMissionDesktop>
        )}
      </>
    );
  } else if (page === 2) {
    return (
      <>
        <SEO />

        {isMobile ? (
          <LayoutMissionMobile
            title={'Gastos hormigas'}
            confettiFire={false}
            number={5}
            secondLayoutForShortcuts={true}
            setPage={setPage}
            pageNumber={1}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep2
              setPage={setPage}
              antExpenses={antExpenses}
              setAntExpenses={setAntExpenses}
            />
          </LayoutMissionMobile>
        ) : (
          <LayoutMissionDesktop
            title={'Gastos hormigas'}
            confettiFire={false}
            number={5}
            numberAdjust={-0.17} // cambiar a -1
            secondLayoutForShortcuts={true}
            setPage={setPage}
            pageNumber={1}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep2
              setPage={setPage}
              antExpenses={antExpenses}
              setAntExpenses={setAntExpenses}
            />
          </LayoutMissionDesktop>
        )}
      </>
    );
  } else if (page === 3) {
    return (
      <>
        <SEO />

        {isMobile ? (
          <LayoutMissionMobile
            title={'Gastos hormigas'}
            confettiFire={false}
            number={5}
            secondLayoutForShortcuts={true}
            setPage={setPage}
            pageNumber={2}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep3 handleClick={handleClick} />
          </LayoutMissionMobile>
        ) : (
          <LayoutMissionDesktop
            title={'Gastos hormigas'}
            confettiFire={false}
            number={5}
            numberAdjust={-0.17} // cambiar a -1
            secondLayoutForShortcuts={true}
            setPage={setPage}
            pageNumber={2}
            onHouseClick={() => navigate(Route.budget)}
          >
            <M5BudgetStep3 handleClick={handleClick} />
          </LayoutMissionDesktop>
        )}
      </>
    );
  }
};

export default BudgetMission5;
